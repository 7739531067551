<script>

export default {
    name: "ReclutamientoView",
}
</script>
<template>
    <div>
        <div class="container-fuild px-0">
            <header class="header bg-primary px-3 py-3 d-flex justify-content-between align-items-center">
                <router-link :to="{ name: 'ListaDeVacantes' }">
                    <img src="/img/HXLogoWhite.png" alt="Logo" width="200">
                </router-link>
                <nav class="d-flex justify-content-center" >
                    <!-- <router-link class="nav-item btn btn-primary" :to="{ name: 'ListaDeVacantes'}">
                       Lista de vacantes
                    </router-link>
                    <router-link class="nav-item btn btn-primary" :to="{ name: 'NavegacionAspirante'}">
                     Seguimiento de postulación
                    </router-link> -->
                    <router-link class="" :to="{ name: 'ListaDeVacantes'}">
                        <vs-button size="large" primary ><i class="fa-solid fa-list mr-2"></i> Lista de vacantes</vs-button>
                    </router-link>
                    <router-link class=" " :to="{ name: 'NavegacionAspirante'}">
                        <vs-button size="large" primary><i class="fa-solid fa-magnifying-glass mr-2"></i> Seguimiento de postulación</vs-button>
                    </router-link>
                </nav>
            </header>
            <vs-button danger to="/dashboard" class="ml-4 mt-4">
                <i class="fa-solid fa-chevron-left pr-1"></i>
                Volver a la página principal
            </vs-button>
            <div class="py-2 mb-5">
                <router-view>
                </router-view>
            </div>
        </div>
       
    </div>
</template>
<style scoped>
/* .header {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    width: 98%;
    margin: 0 auto;
}
.header * {
    color: #f5f5f5;
} */
nav .router-link-active {
    color: #ffffff;
}
.nav-item {
    font-size: 1.2rem;
    font-weight: 500;
}

.nav-item::before {
    padding: 2rem;
    color: #f5f5f5;
}
.nav-item:first-child::before {
    content: "";
}

</style>